<template>
  <div class="pl-4">
    <b-alert show variant="success" v-if="partner.status === 1 /* коректировка */">
      <p>
        На этапе проверки своих данных партнер запросил изменение.<br />
        Сообщение от партнера:
      </p>
      <p>{{ partner.comment }}</p>
    </b-alert>
    <div class="mb-3 row">
      <div class="pl-5 ml-1 col">
        <h2 class="h4">Партнер {{ partner.name }}</h2>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex">
        <ul class="mr-5 list">
          <li><b>ИНН</b>{{ partner.inn }}</li>
          <li><b>Менеджер</b>{{ partner.manager }}</li>
          <li><b>Контактное лицо</b>{{ partner.contactPerson }}</li>
          <li><b>Статус</b>{{ partnerStatus }}</li>
        </ul>

        <ul class="list">
          <li><b>Телефон</b>{{ partner.contactPersonPhone }}</li>
          <li v-if="partner.contactPersonMobilePhone"><b>Телефон моб.</b>{{ partner.contactPersonMobilePhone }}</li>
          <li v-if="partner.webSite"><b>Сайт</b>{{ partner.webSite }}</li>
          <li><b>Email</b>{{ partner.contactPersonEmail }}</li>
        </ul>
      </div>
      <div class="col-auto">
        <div v-if="isManufacturer" class="pr-3 d-flex align-items-center">
          <label class="pr-3 switch-label" for="switcher">Добавить информацию о производителе</label>
          <b-form-checkbox id="switcher" v-model="isShowManufacturerInfoInCertificate" name="check-button" switch @change="changeManufacturerInfo()"></b-form-checkbox>
        </div>
        <div class="text-right">
          <b-button class="mt-3" v-if="isManufacturer && officesDD && officesDD.length > 0" variant="success" @click="onRequestCertificate">Запросить сертификат</b-button>
        </div>
      </div>
    </div>

    <div class="mb-3 row" v-if="isManufacturer || partner.manufacturerId">
      <div class="pl-5 ml-1 col">
        <h2 class="h4">Производитель {{ manufacturer.name }}</h2>
      </div>
    </div>

    <div class="row" v-if="isManufacturer || partner.manufacturerId">
      <div class="col">
        <ul class="mr-5 list">
          <li><b>ИНН</b>{{ manufacturer.inn }}</li>
          <li><b>Email</b>{{ manufacturer.contactPersonEmail }}</li>
        </ul>
      </div>
    </div>

    <div class="row" v-if="(isManager || isLeader) && partner.managerId == userId && partner.status == 1">
      <div class="pl-5 col">
        <b-button variant="success" @click="onPartnerEdit">Редактировать</b-button>
      </div>
    </div>

    <div class="pt-5 row">
      <div class="pl-5 col">
        <b-tabs content-class="mt-3" ref="tabs" v-model="tabIndex">
          <b-tab title="Сертификаты" active>
            <div v-if="!partnerCertificates.length">
              Нет истории сертификатов
            </div>
            <b-table
              v-if="partnerCertificates.length"
              hover
              selectable
              select-mode="single"
              :items="partnerCertificates"
              :fields="historyFields"
              :sort-by.sync="certificatesSortBy"
              :sort-desc.sync="certificatesSortDesc"
              @row-selected="onHistoryRowSelected"
            >
              <template #cell(bitlyUrl)="data">
                <a :href="data.value" target="_blanck" class="link">{{ data.value }}</a>
              </template>
            </b-table>
          </b-tab>

          <b-tab title="Офисы">
            <div v-if="!partnerOffices.length">Нет офисов</div>
            <b-table
              v-if="partnerOffices.length"
              hovercertificate
              selectable
              select-mode="single"
              :items="partnerOffices"
              :fields="officesFields"
              @row-selected="onOfficesRowSelected"
            ></b-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div class="mt-3 mb-5 row">
      <div class="pl-5 col">
        <b-button v-if="tabIndex == 0 && !!historyRowSelect" class="mr-2 btn-danger" v-b-modal.modalDeleteCertificate>Удалить сертификат</b-button>
        <template v-if="tabIndex == 0 && historyRowSelected && tabIndex !== 1">
          <a :href="pdfHref" class="mr-3 btn btn-success">Загрузить</a>
          <b-button @click="showCertificate(tabIndex)" variant="success" class="mr-3">Посмотреть</b-button>
          <b-button v-if="isLeader || isManager" v-b-modal.modalRevokeCertifaicate variant="success" class="mr-3">Аннулировать</b-button>
          <b-button v-if="showHistoryButton" variant="success" class="mr-3" v-b-modal.modalHistoryCertifaicate>История</b-button>
        </template>
      </div>
    </div>

    <div class="mt-3 mb-5 row" v-if="tabIndex == 1 && officesRowSelected && isAdmin">
      <div class="pl-5 col">
        <b-button class="mr-2 btn-danger" v-b-modal.modalDeleteSelfRetail v-if="isAdmin">Удалить офис</b-button>
        <b-button variant="success" class="mr-3" :disabled="isEditDisabled" @click="onOfficeEdit">Редактировать</b-button>
        <span v-if="isEditDisabled" class="text-danger">Данная точка не подтверждена и не может быть отредактирована</span>
      </div>
    </div>

    <RightSidebar
      :form="idEditFormName"
      :form-data="idEditFormData"
      :mode="idEditFormMode"
      :show="idEditFormShow"
      @update:show="idEditFormShow = $event;idEditFormData = {};"
      @saved:form-data="onSavedEditForm"
    ></RightSidebar>

    <b-modal id="modalRevokeCertifaicate" title="Укажите дату аннулирования сертификата" ok-title="Аннулировать" cancel-title="Отмена" centered @ok="onRevokeCertificate">
      <b-form-datepicker v-model="certificateRevokeDate" :min="todayDate" class="mb-2"></b-form-datepicker>
    </b-modal>

    <b-modal id="modalDeleteCertificate" title="Удалить сертификат" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onDeleteCertificate">
      Подтвердите удаление сертификата
    </b-modal>

    <b-modal id="modalDeleteSelfRetail" title="Удалить офис" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onDeleteSelfRetail">
      Подтвердите удаление офиса
    </b-modal>

    <b-modal id="modalHistoryCertifaicate" title="История продления сертификата" ok-only ok-title="Закрыть" centered @show="onLoadHistoryCertificate">
      <div class="row">
        <div class="col">
          <b-table ref="selectableTable" hover selectable select-mode="single" :fields="modalHistoryFields" :items="certifyReReleaseHistory" :no-local-sorting="true"></b-table>
          <div v-if="!certifyReReleaseHistoryTotal" class="text-center">Нет записей</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters, mapActions } from "vuex";
import { DateFormat } from "@/helpers";
import { PartnerStatus } from "@/constants";
import { RightSidebar } from "@/components";

export default {
  name: "PartnerDetails",
  components: {
    RightSidebar
  },
  data() {
    let todayDate = DateFormat.getDate(new Date());
    return {
      dealerId: this.$route.params.id,
      allowRevokeCertificate: false,
      pdfHref: null,
      activeCertificateKey: null,
      todayDate: todayDate,
      certificateRevokeDate: todayDate,
      certificatesButtonsVisibility: false,
      certificatesSortBy: "dateOfIssue",
      certificatesSortDesc: true,
      isShowManufacturerInfoInCertificate: false,

      address: null,
      partnerStatus: "",

      tabIndex: 0,

      historyFields: [
        { key: "spotName", label: "Наименование" },
        { key: "spotAddress", label: "Фактический адрес" },
        { key: "dateOfIssue", label: "Дата", formatter: item => DateFormat.getDate(item) },
        { key: "expirationDate", label: "Действителен до", formatter: item => DateFormat.getDate(item) },
        { key: "status", label: "Статус" },
        { key: "numberOfRerelease", label: "Пролонгирован", tdClass: 'text-center'},
        { key: "bitlyUrl", label: "Ссылка на сертификат" }
      ],
      historyRowSelect: null,

      officesFields: [
        { key: "id", label: "ID" },
        { key: "spotName", label: "Наименование" },
        { key: "spotAddressCoordinates", label: "Координаты" },
        { key: "spotAddress", label: "Фактический адрес" },
        { key: "spotAddressPhone", label: "Телефон" },
        { key: "spotAccost", label: "Менеджер офиса продаж" },
        { key: "hasCertificate", label: "Сертификат", formatter: item => (item ? "Да" : "Нет") },
        { key: "certificateExpirationDate", label: "Действителен до", formatter: item => DateFormat.getDate(item) }
      ],
      officesRowSelect: null,

      idEditFormName: null,
      idEditFormData: {},
      idEditFormMode: "EDIT",
      idEditFormShow: false,

      modalHistoryFields: [
        { key: "createdAt", label: "Дата продления", formatter: item => DateFormat.getDate(item) },
        { key: "createdBy", label: "Продлил" }
      ]
    };
  },
  methods: {
    ...mapActions("partners", [
      "fetchPartner",
      "certifyPartner",
      "fetchAllPartnerCertificates",
      "fetchAllPartnerOffices",
      "fetchManufacturerPartnerCertificates",
      "fetchManufacturerPartnerOffices",
      "dealerCertificateRevocation",
      "editDealer",
      "changeAddress",
      "toggleManufacturerInfo",
      "fetchManufacturerById",
      "fetchCertifyReReleaseHistory",
      "deleteDealerCertificate",
      "deleteDealersSelfRetail",
    ]),
    async onDeleteCertificate() {
      this.loading = true;

      this.deleteDealerCertificate({
        dealerId: this.dealerId,
        certificateId: this.historyRowSelect.id
      }).then(async (res) => {
        if (this.isManufacturer) {
          await this.fetchManufacturerPartnerCertificates({ dealerId: this.dealerId, manufacturerId: this.partnerId });
        } else {
          await this.fetchAllPartnerCertificates({ dealerId: this.$route.params.id });
        }

        this.loading = false;

        if (res.ok) {
          this.historyRowSelect = undefined;
        }
      });
      return;
    },
    async onDeleteSelfRetail() {
      this.loading = true;
      this.deleteDealersSelfRetail(this.officesRowSelect.id).then(async (res) => {
        if (this.isManufacturer) {
          await this.fetchManufacturerPartnerOffices({ id: this.$route.params.id, manufacturerId: this.partnerId });
        } else {
          await this.fetchAllPartnerOffices({ id: this.$route.params.id, params: { first: 0, rows: -1 } });
        }

        this.loading = false;

        if (res.ok) {
          this.officesRowSelect = undefined;
        }
      });
    },
    onCertifyOffice() {
      this.loading = true;
      let adr = {
        dealerId: this.dealerId,
        addressId: this.address
      };
      console.log(adr);
      this.certifyPartner(adr).then(res => {
        this.loading = false;
        if (res.ok) this.isSidebarOpen = !this.isSidebarOpen;
      });
    },
    getStatus() {
      this.partnerStatus = PartnerStatus.translate(this.partner.status);
      console.log(`this.partnerStatus ${this.partnerStatus}`);
    },
    onHistoryRowSelected(items) {
      this.historyRowSelect = (items || [])[0];
    },
    onOfficesRowSelected(items) {
      this.officesRowSelect = (items || [])[0];
    },
    onRevokeCertificate() {
      let revokeCertData = {
        dealerId: this.dealerId,
        revocationDate: this.certificateRevokeDate,
        spotAddressId: this.historyRowSelect.spotAddressId
      };
      this.dealerCertificateRevocation(revokeCertData).then(res => {
        console.log(res);
        if (!res.ok) return
        location.reload();
      });
    },
    showCertificate(tabIndex) {
      if (tabIndex === 0 /* certificate history */) {
        let route = this.$router.resolve(`/certificatedealer?Key=${this.historyRowSelect.key}`);
        window.open(route.href, "_blank");
      }
    },

    onRequestCertificate() {
      this.idEditFormName = "RequestCertificateForm"
      this.idEditFormData = { address: null };
      this.idEditFormShow = true;
    },
    onPartnerEdit() {
      this.idEditFormName = "PartnerForm"
      this.idEditFormData = { ...this.partner };
      this.idEditFormShow = true;
    },
    onOfficeEdit() {
      this.idEditFormName = "OfficeForm"
      this.idEditFormData = { partnerId: this.dealerId, ...this.officesRowSelect };
      this.idEditFormShow = true;
    },

    onSavedEditForm() {
      this.fetchAllData()
    },

    changeManufacturerInfo() {
      this.toggleManufacturerInfo(this.partner.id);
    },

    onLoadHistoryCertificate() {
      this.fetchCertifyReReleaseHistory(this.historyRowSelect.id);
    },

    fetchAllData() {
        this.fetchPartner(this.$route.params.id).then(() => {
        this.getStatus();
        if (this.partner.manufacturerId) {
          this.fetchManufacturerById(this.partner.manufacturerId);
        }
        if (this.isManufacturer) {
          this.fetchManufacturerPartnerCertificates({ dealerId: this.$route.params.id, manufacturerId: this.partnerId });
          this.fetchManufacturerPartnerOffices({ id: this.$route.params.id, manufacturerId: this.partnerId });
        } else {
          this.fetchAllPartnerCertificates({ dealerId: this.$route.params.id });
          this.fetchAllPartnerOffices({ id: this.$route.params.id, params: { first: 0, rows: -1 } });
        }
      });
    }
  },
  watch: {
    historyRowSelected(val) {
      if (val && this.historyRowSelect) {
        this.activeCertificateKey = this.historyRowSelect.key;
        this.pdfHref = `${process.env.VUE_APP_API}/dealercertificates/pdf/${this.activeCertificateKey}`;
      } else {
        this.activeCertificateKey = null;
        this.pdfHref = "";
      }
    },

    partner(val) {
      this.isShowManufacturerInfoInCertificate = val.isShowManufacturerInfoInCertificate;
    }
  },
  computed: {
    ...mapGetters(["userRole", "isAuth", "isAdmin", "isLeader", "isManager", "isCurator", "isManufacturer", "isDealer"]),
    ...mapState({
      userId: state => state.auth.user.id,
      partnerId: state => state.auth.user.partnerId
    }),
    ...mapState("addresses", {
      daDataAddresses: state => state.daDataAddresses
    }),
    ...mapState("partners", {
      partner: state => state.partner,
      partnerOffices: state => state.partnerOffices,
      dealerAddressesTotal: state => state.dealerAddressesTotal,
      partnerCertificates: state => state.partnerCertificates,
      partnerCertificatesTotal: state => state.partnerCertificatesTotal,
      isShowAddNewDealerForm: state => state.showAddNewPartnerForm,
      isDealerExist: state => state.partnerExists,
      isDealerConfirmed: state => state.dealerConfirmed,
      dealerCertificationProcess: state => state.dealerCertificationProcess,
      addressCertificationRequests: state => state.addressCertificationRequests,
      dealerCertificate: state => state.dealerCertificate,
      dealerVerifyDetailes: state => state.dealerVerifyDetailes,
      anotherManufacturerRequest: state => state.anotherManufacturerRequest,
      usersByPartnerRegion: state => state.usersByRegion,
      manufacturer: state => state.manufacturer,
      certifyReReleaseHistory: state => state.certifyReReleaseHistory,
      certifyReReleaseHistoryTotal: state => state.certifyReReleaseHistory.length
    }),
    ...mapGetters("partners", ["officesDD"]),

    historyRowSelected() {
      return !!this.historyRowSelect && this.historyRowSelect.status === "Активный";
    },
    officesRowSelected() {
      return !!this.officesRowSelect && this.isAdmin;
    },
    isEditDisabled() {
      return !this.officesRowSelect.isApproved;
    },

    showHistoryButton() {
      return this.tabIndex === 0 && this.historyRowSelect.numberOfRerelease > 0;
    }
  },
  created() {
    this.fetchAllData()

    this.allowRevokeCertificate = this.isManager || this.isLeader;
  }
};
</script>

<style lang="scss">
.switch-label {
  font-weight: bold;
}
</style>
